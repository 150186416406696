import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
} from "@chakra-ui/react";

import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { useState } from "react";
import { register } from '../apis/registerApi';
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: ''
  });

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    let inputValue = event.target.value;

    if (event.target.name === "phone") {
      // Remove non-digit characters from the input
      inputValue = inputValue.replace(/\D/g, '');

      // Check if the length is more than 10
      if (inputValue.length > 10) {
        return; // Don't update the state if the length is more than 10
      }

      // Apply the phone number format
      inputValue = inputValue.replace(/(\d{3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
        let formattedNumber = p1;
        if (p2) formattedNumber += `-${p2}`;
        if (p3) formattedNumber += `-${p3}`;
        return formattedNumber;
      });
    }

    setFormValues({
      ...formValues,
      [event.target.name]: inputValue
    });
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    console.log("form values are ", formValues.firstname, formValues.lastname, formValues.phone, formValues.email, formValues.password);
    try {
      const response = await register(formValues.firstname, formValues.lastname, formValues.phone, formValues.email, formValues.password);
      navigate(response.redirectPath)
      // Navigate to the next page or handle the response as needed
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#edf3f8"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="#38A169" />
        <Heading color="#38A169">Sign Up</Heading>
        <Text fontSize="md">It seems you aren't signed up. Please Register down below!</Text>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={registerSubmit}>
            <Stack
              p="2rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              spacing="6"
              height="400px"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<chakra.span color="gray.300"><FaUserAlt /></chakra.span>}
                  />
                  <Input
                    type="text"
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    required
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<chakra.span color="gray.300"><FaUserAlt /></chakra.span>}
                  />
                  <Input
                    type="text"
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    required
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<chakra.span color="gray.300"><PhoneIcon /></chakra.span>}
                  />
                  <Input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                    value={formValues.phone}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<chakra.span color="gray.300"><EmailIcon /></chakra.span>}
                  />
                  <Input
                    type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    placeholder="Email Address"
                    required
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<chakra.span color="gray.300"><PhoneIcon /></chakra.span>}
                  />
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formValues.password}
                    onChange={handleInputChange}
                    required
                  />
                </InputGroup>
              </FormControl>
              <Button
                type="submit"
                variant="solid"
                colorScheme="#87CEEB"
                background="#87CEEB"
                width="full"
              >
                Register
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Register;